.itDev .Iframe_wrapper{
    max-width: 100vw;

    width:calc(100vw - 55px);
    height: calc(100vw - 72px);
    align-items: flex-start;
    justify-content: flex-start;

}
.itDev .BodyBlock_wrap{
    padding-left: 0;
    padding-right: 0;

}
.MainBlock_wrapper {
  display: flex;





    overflow: hidden;

}

.MainBlock_wrap {
    width: 100%;
    height: calc(100vh - 100px);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  minmax(300px, 3fr) minmax(135px, 15vh) minmax(355px, 2fr);

    align-self: center;
    margin: 0 auto;

grid-gap: 20px;
    overflow: auto;
}

.MainBlock_logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.MainBlock_logo > img {
    width: 100px;
}

.MainBlock_text_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns:1fr 1.3fr ;

    justify-content: center;
    color: #ffffff;
    max-width: 1900px;
    margin: 0 auto;




}

.MainBlock_text_left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 10px;
    flex-direction: column;
    padding-right: 20px;
    max-width: 700px;

    align-self: center;



}
.MainBlock_text_left_wrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.MainBlock_text_left > span{
    font-size: clamp(22px, 1.5vw, 36px);
    color: #FFF;

    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.MainBlock_text_left > p {
    font-size: clamp(12px, 1vw, 22px);
    text-align: left;
}

.MainBlock_text_right {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    max-height: 100%;
    min-height: 150px;
    max-width: 100%;

}
.MainBlock_text_right > svg{
    height: 100%;
    width: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    max-width: 100%;
}
.MainBlock_text_right > img {

    bottom: 0;
    position: absolute;


}

.MainBlock_text_right_bg1 {
    position: absolute;
    z-index: 10;
    right: 0;
    width: auto;
    height: 140%;
}

.MainBlock_text_right_bg2 {
    position: absolute;
    z-index: 11;
    left: 0;
    width: auto;
    height: 90%;

}

.MainBlock_card_wrap {
    width: 100%;
padding: 0 40px 20px;
    overflow: hidden;
    height: 100%;




}

.MainBlock_card {
    width: max-content;
    height: 100%;
    background: #044778;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr max-content;


/*overflow: hidden;*/

}
.MainBlock_card_img{
    width: 100%;
    height: 100%;
/*    overflow: hidden;*/
    background-repeat: no-repeat;
    background-size: 100%;


}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: inherit;
    color: white;
    cursor: pointer;
    height: 100%;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 400px;

}
/*.swiper-slide{
    overflow: hidden;
}*/
.MainBlock_card_text{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    font-size: clamp(12px, 1vw, 16px);
    cursor: pointer;
}
.MainBlock_card_text:hover{

    background: #17517c;
}
.MainBlock_menu{
    border-radius: 15px;
padding-top: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: clamp(12px, 1vw, 18px);
    font-weight: 600;
    letter-spacing: 1px;
}
.MainBlock_menu_wrap .slick-slider,
.MainBlock_menu_wrap .slick-list,
.MainBlock_menu_wrap .slick-track{
    width: 100%;
    height: 100%;
}
.MainBlock_menu_wrap .slick-slide > div{
    height: 100%;
    display: flex;
    align-items: center;

}
.MainBlock_menu_wrap{
    width:max-content;
margin: 0 auto ;

display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #044778;

    height: 100%;

}
.MainBlock_menu_wrap .slick-track{
   grid-gap: 20px;
    display: flex;
    padding: 10px;

}
.MainBlock_menu_wrap .slick-slider{
    width: 800px;
}
.MainBlock_menu{
    padding-bottom: 10px;
    background: linear-gradient(180deg, #0D82EE 0%, rgba(0, 72, 211, 0.40) 100%);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
    height: calc(100% - 20px);
    display: flex!important;
    align-items: center;
    justify-content: center;
    color:white;
}
.MainBlock_menu.activeM{
    height: 100%

}
.MainBlock_card a{
    text-decoration: none;
    color:white;
}

.MainBlock_card_wrap .swiper-free-mode > .swiper-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    min-width: 200px;
}
.MainBlock_card_wrap .swiper-backface-hidden .swiper-slide{
display: flex;
    align-items: center;
    justify-content: center;
    cursor: inherit;
}
.MainBlock_card_wrap .swiper-wrapper{
grid-gap: 15px;
    padding: 10px 0 32px;
    width: max-content;

    margin: 0px auto;
}
.MainBlock_text_wrapper{
    display: flex;
    background: linear-gradient(274deg, #305682 29.94%, rgba(28, 72, 123, 0.00) 100.16%);
    padding: 0 40px;
    height: 100%;

}

:root {
    --swiper-theme-color: #ffffff!important;
}
.MainBlock_menu_text{
    color: white;
    font-size: clamp(22px, 5vw, 26px);
}

.itDev .NewScV_header{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: max-content;
    top: 0;
    left: 0;

}
.itDev .NewScV_header.active{
    grid-template-rows: max-content 1fr;
    height: 100vh;
}

.itDev .NewScV_header .click_area_nav{
    width: 100%;
    height: calc(100% + 20px);
    background-color: inherit;
    display: block;
    margin-top: -20px;

    z-index: 999;
}

.MainBlock_wrapper .HeaderBlock_wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: max-content;
margin: 0;
    box-shadow: none;
    padding: 10px 40px;
    z-index: 10;
    grid-gap: 10px;


    background: linear-gradient(274deg, #305682 29.94%, rgba(28, 72, 123, 0.00) 100.16%);
}
.MainBlock_wrapper .HeaderBlock_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    text-align: left;
    grid-gap: 10px;
    text-decoration: none;
    color: white;
}
.MainBlock_wrapper .HeaderBlock_item svg{
    height: 3.8vh;

}
.MainBlock_wrapper .HeaderBlock_item  span{
    line-height:  clamp(12px, 5vw, 22px);
}
.MainBlock_wrapper .cifra_logo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    font-size: clamp(12px, 5vw, 20px);
    line-height:      clamp(12px, 5vw, 22px);

}

.MainBlock_wrapper .HeaderBlock_item span{
    font-size: clamp(12px, 1vw, 20px);
    line-height: clamp(10px, 1vw, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: max-content;
    max-width: max-content;
    min-width: min-content;
}
.MainBlock_wrapper .cifra_logo_text > span:nth-child(2){
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 500;
}
.MainBlock_wrapper .cifra_logo_text > span:nth-child(1){
    font-size: clamp(10px, 1vw, 18px);
}

.MainBlock_wrapper .cifra_logo_text > span:nth-child(3){
    font-size: clamp(10px, 1vw, 18px);
}
.MainBlock_wrapper .HeaderBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 99999;
}


.MainBlock_wrapper .Contact_block > a{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    text-decoration: none;
    color: white;

}

.MainBlock_wrapper  .Contact_block:hover  a{
    color: #096dd9;

}

.MainBlock_wrapper .home_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}



.MainBlock_wrapper .home_icon_text{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.MainBlock_double_items{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
}
.itDev .lang_block{
    font-size: clamp(16px, 1vw, 20px);
    width: max-content;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding-right: 20px;
    font-weight: 500;
}
.itDev .lang_block > span:last-child,
.itDev .lang_block > span:first-child{
    cursor: pointer;
}
.itDev .lang_block > span:last-child:hover,
.itDev .lang_block > span:first-child:hover{

    color: #659365;
}
.itDev .lang_btn_item.active{
    color: #659365;
}
.MainBlock_menu_wrap .slick-dots li.slick-active button:before,
.MainBlock_menu_wrap .slick-dots li button:before{
    color: white;
}
.Feedback_btn{
    border-radius: 15px;
    border: 1px solid white;
    padding: 7px 15px;
    font-weight: 600;
    font-size: clamp(12px, 1vw, 16px);
}
.itDev .HeaderBlock_wrap > a svg,
.itDev .HeaderBlock_wrap img{
    max-height: 50px;
    height: 3.8vh;
}
@media (max-width: 1150px){
    .MainBlock_wrapper .Header_logo_text,
    .MainBlock_wrapper .cifra_logo_text{
        display: none!important;
        background: #7A2525;
    }
}
@media (max-width: 780px){
    .itDev .HeaderBlock_item:not(.home_icon)  {
        display: none!important;}
}
.swiper-autoheight, .swiper-autoheight .swiper-slide{

}
