@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); /* Box sizing rules */
* {
    font-family: 'Roboto', sans-serif !important;


}
.BodyBlock_wrap{
    padding: 10px 10px 0 10px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px 1fr;

}
.BodyBlock_wrap .titleBlockIframe{
    font-size: clamp(18px, 5vw, 26px);
font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    letter-spacing: 2px;
    width: 100%;
    grid-gap: 10px;
}
.BodyBlock_wrap iframe{

    background: inherit;
    width: 100%;
    height: 100%;
    border: none;


}
.titleBlockIframe > span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    align-self: center;
    height: max-content;
}
.titleBlockIframe > span svg{
    height: 100%;
    display: flex;
}
.titleBlockIframe > span:last-child svg{
    display: none;
}
.errorT {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/404.svg");
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: center;
}

