body {
  margin: 0;
  font-family: 'Astana', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Astana', sans-serif;
}
::-webkit-scrollbar {
  scrollbar-width: 2px;
  width: 2px;
  background-color: transparent;

  scrollbar-width: thin!important;
  scrollbar-color: #414e6042 #414e6042!important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
  scrollbar-width: thin!important;
  scrollbar-color: #414e6042 #414e6042!important;

}
*{
  scrollbar-width: thin!important;
  scrollbar-color: #414e6042 #414e6042!important;
}

