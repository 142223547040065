.NewScV_footer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: max-content;
    bottom: 0;
    left: 0;
    grid-column: 1/ span 2;
}
.FooterBlock_wrapper {
    width: 100%;
    height: max-content;
    margin-bottom: -40px;
    display: flex;
    flex-direction: column;
    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;
}
.FooterBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 1005;
}
.FooterBlock_wrap{
   display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
    background: #024280;
    /*box-shadow: 2px 4px 4px rgba(0, 0, 0, .25);*/
    box-shadow: -2px -4px 4px rgba(0, 0, 0, .25);
    padding: 10px 0;
    z-index: 1000;
    margin-bottom: -1px;
}
.FooterBlock_wrapper svg{
    margin-bottom: -1px;
}
.NewScV_footer.active{
    grid-template-rows: 1fr  max-content;
    height: 100vh;
}
.NewScV_footer .click_area_nav{
    background:inherit;

    width: 100%;
    height: calc(100% + 20px);

    display: block;
}
.FooterBlock_wrapper.active {
    margin-bottom: 0;
    transition-delay: 0s;
}
.FooterBlock_wrap> div{
    cursor: pointer;
}
