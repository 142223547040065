.App{
    background: linear-gradient(180deg, #112946 7.79%, rgba(17, 41, 70, 0.96) 68.29%, rgba(17, 41, 70, 0.93) 99.22%);
}
.NewScV_wrapper{
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;

}
.NewScV_wrap{
    width: 100%;
    height: 100%;
    display: grid;

    position: absolute;
    background:  url('../assets/img/img.png') no-repeat 80% 15% ;
    background-size: 80%;
    grid-template-rows: 1fr;
    grid-template-columns: 55px 1fr;
    max-height: 100vh;
    overflow: hidden;
}
.NewScV_header{

}
.NavMenu_wrap{
    grid-column: 1/ span 1;
    grid-row: 1/ span 3;

}
.BodyBlock_wrap{
    display: flex;
    grid-row: 1/span 1;
    grid-column: 2/ span 1;
    width: 100%;
    height: 100%;
}

