.HeaderBlock_wrapper{
    width: 100%;
    height: max-content;
margin-top: -65px;


    display: flex;
    flex-direction: column;

    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;

}
.HeaderBlock_wrapper.active{
    margin-top: 0;

    transition-delay: 0s;
}
.NewScV_header{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: max-content;
    top: 0;
    left: 0;

}
.NewScV_header.active{
    grid-template-rows: max-content 1fr;
    height: 100vh;
}

.NewScV_header .click_area_nav{
    width: 100%;
    height: calc(100% + 20px);
    background-color: inherit;
    display: block;
    margin-top: -20px;

    z-index: 999;
}

.HeaderBlock_wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: max-content;
    background: #024280;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    z-index: 99999;
    margin-bottom: -1px;
}
.HeaderBlock_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    text-align: left;
    grid-gap: 10px;
    text-decoration: none;
    color: white;
}
.HeaderBlock_item svg{
    height: 3.8vh;

}
.HeaderBlock_item  span{
    line-height: 16px;
}
.cifra_logo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    height: max-content;

}
.cifra_logo_text > span:nth-child(1){
    font-size: 0.8em;
}
.cifra_logo_text > span:nth-child(2){
    font-size: 1em;
    font-weight: 500;
}
.cifra_logo_text > span:nth-child(3){
    font-size: 0.8em;
}
.HeaderBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 99999;
}


.Contact_block > a{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    text-decoration: none;
    color: white;

}

.Contact_block:hover  a{
    color: #096dd9;

}

.home_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}
.home_icon span{
    font-size: 16px;



}

.home_icon_text{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
