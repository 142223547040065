@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: 'Roboto', sans-serif !important;


}

.App {
    text-align: center;
    letter-spacing: .8px;
    font-weight: 300;
    width: 100%;
    height: 100%;
    overflow: hidden;
    scrollbar-color: transparent; /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: thin;


}

body {
    background: inherit;
    /* background-color: #282c34;*/

}

#root {
    width: 100vw;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-button {
    width: 0;
    opacity: 0;
}

::-webkit-scrollbar-corner {
    opacity: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-button {
    width: 0;
    opacity: 0;
}

::-webkit-scrollbar-corner {
    opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: inherit;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.bodyTest {
    /*  background-image: url(/static/media/bg-pattern.8cfe4242.svg), radial-gradient(farthest-side ellipse at 10% 0, #414e60 20%, #94a3b1);*/

    letter-spacing: 0.5px;

    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;

    color: #ffffff;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
